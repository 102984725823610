<template>
  <v-app>
    <ChangeIssuesStatusDialog
      :StoreObj="StoreObj"
      v-if="dialogChangeIssuesStatus == true"
      :dialogChangeIssuesStatus="dialogChangeIssuesStatus"
      @clicked="dialogChangeIssuesStatusEmit"
      :ListIssuesOfUserAcrossAllProjectsArray="ListIssuesOfUserAcrossAllProjectsArray"
      :GetAllIssueCommentsList="GetAllIssueCommentsList"
      :current_pointer="current_pointer"
      :callIntiateDataDialog="callIntiateDataDialog"
    />

    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" /> 
    <CreateIssueDialog
      v-if="dialogCreateIssue == true"
      :dialogCreateIssue="dialogCreateIssue"
      :selectedFilesFromDrop="selectedFilesFromDrop"
      @clicked="dialogCreateIssueEmit"
    />
    <CreateProject :dialogCreateProject="dialogCreateProject" @clicked="dialogCreateProjectEmit" :StoreObj="StoreObj" />
    <TestersDevelopersInformation
      :dialogTesterDeveloperInformation="dialogTesterDeveloperInformation"
      :StoreObj="StoreObj"
      @clicked="dialogTesterDeveloperInformationEmit"
    />
    <ActivateDeactivateProjectDialog
      :dialogActivateDeactivateProject="dialogActivateDeactivateProject"
      @clicked="dialogActivateDeactivateProjectEmit"
      :StoreObj="StoreObj"
    />
    <ChangeOwnerShip
      :StoreObj="StoreObj"
      :dialogChangeOwnership="dialogChangeOwnership"
      @clicked="dialogChangeOwnershipEmit"
    />
    <v-app-bar dense app class="elevation-1" color="white">
      <v-card class="elevation-0">
        <v-img width="140px" contain src="@/assets/BugTraker.png"></v-img>
      </v-card>
      <v-divider vertical class="ml-4"></v-divider>
      <v-spacer></v-spacer>
      <div class="FontSize">
        {{ $store.getters.get_user_email }}
      </div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :disabled="overlay == true" v-bind="attrs" v-on="on" icon @click="htmlToImageMethod()" color="primary">
            <v-icon>mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Facing Issues Using BugTrakr ? Click To Report</span>
      </v-tooltip>
      <v-menu bottom origin="center center" v-model="reportMenu" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-badge :content="websocketDataArray.length.toString()" color="red"> </v-badge>
          <v-icon pa-2 color="primary " class="pl-1 pr-0" dark v-bind="attrs" v-on="on">mdi-bell-ring-outline</v-icon>
        </template>
        <v-card class="elevation-0">
          <v-toolbar dark dense color="primary">
            <v-icon>mdi-bell-ring-outline</v-icon>
            <v-spacer></v-spacer>
            <v-toolbar-title> Notification </v-toolbar-title>
          </v-toolbar>
          <v-card class="elevation-0" width="300" height="150">
            <v-list-item dense three-line v-for="(item, idx) in websocketDataArray" :key="idx" class="py-0 my-0">
              <v-list-item-content @click="goInsideProject(item)">
                <v-list-item-title>Issue Reported On {{ item.projectName }}</v-list-item-title>
                <v-list-item-subtitle class="mt-n3">
                  Description:
                  {{ item.issueDescription }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="mt-n3">
                  Reported By:
                  {{ item.issueReportedBy }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mx-3 mt-n2"></v-divider>
          </v-card>
        </v-card>
      </v-menu>
      <v-btn icon to="/" color="primary" class="ml-4">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <div ref="home">
        <v-card class="elevation-0">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="5">
              <v-toolbar dense class="elevation-0">
                <v-tabs v-model="tab">
                  <v-tab class="text-capitalize">Bugs/Enhancements</v-tab>
                  <v-tab class="text-capitalize">Projects </v-tab>
                  <v-tab class="text-capitalize">settings </v-tab>
                  <v-btn
                    small
                    icon
                    color="primary"
                    class="ml-3 mt-4"
                    @click="
                      tab == 0 ? ListIssuesOfUserAcrossAllProjectsMethod() : GetAllProjectsDetailsMethod(project_status)
                    "
                  >
                    <v-icon small class="mb-2">mdi-refresh</v-icon>
                  </v-btn>
                </v-tabs>
              </v-toolbar>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="7">
              <v-toolbar dense class="elevation-0">
                <v-spacer></v-spacer>
                <v-radio-group v-if="tab == 1" v-model="project_status" row class="mt-6">
                  <v-radio label="Active" value="ACTIVE"></v-radio>
                  <v-radio label="In-Active" value="INACTIVE"></v-radio>
                </v-radio-group>
                <v-text-field
                  v-if="tab == 0 || tab == 1"
                  dense
                  outlined
                  class="FontSize borderRadius field_height field_label_size mt-6 mx-2"
                  label="Search"
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
                <v-select
                  dense
                  outlined
                  v-if="tab == 1"
                  class="FontSize borderRadius field_height field_label_size maxWidth mt-6 mx-2"
                  label="Sort By"
                  :items="sort_by_items"
                  v-model="sort_by"
                  item-text="text"
                  item-value="value"
                ></v-select>
                <v-btn
                  v-if="tab == 1"
                  small
                  color="primary"
                  @click="(dialogCreateProject = true), (StoreObj.action = 'CREATE')"
                >
                  <v-icon small>mdi-plus</v-icon>
                  Create Project</v-btn
                >
              </v-toolbar>
            </v-col>
          </v-row>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card-text class="py-0">
                <v-data-table
                  dense
                  :fixed-header="true"
                  :height="windowHeight"
                  class="elevation-0"
                  @click:row="changeIssueStatus"
                  :search="search"
                  :headers="issuesHeader"
                  :footer-props="{ 'items-per-page-options': [50, 100, 250, 500, 1000] }"
                  :items-per-page="50"
                  :items="ListIssuesOfUserAcrossAllProjectsArray"
                >
                  <template v-slot:[`item.sl_no`]="{ item }">
                    <div class="FontSize">
                      {{ ListIssuesOfUserAcrossAllProjectsArray.indexOf(item) + 1 }}
                    </div>
                  </template>
                  <template v-slot:[`item.issue_id`]="{ item }">
                    <div class="FontSize">
                      {{ item.issue_id }}
                    </div>
                  </template>
                  <template v-slot:[`item.issue_type`]="{ item }">
                    <div class="FontSize">{{ item.issue_type }}</div>
                  </template>
                  <template v-slot:[`item.issue_reported_by`]="{ item }">
                    <div class="FontSize">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            v-text="
                              item.is_externally_reported_issue == true
                                ? 'mdi-book-arrow-right-outline'
                                : 'mdi-file-document'
                            "
                            small
                            color="primary"
                            class="mr-2"
                          ></v-icon>
                        </template>
                        <span
                          v-text="item.is_externally_reported_issue == true ? 'External Form' : 'Internal Form'"
                        ></span>
                      </v-tooltip>
                      {{ item.issue_reported_by
                      }}<span class="font-weight-bold" v-if="$store.getters.get_user_email == item.issue_reported_by"
                        >(Me)</span
                      >
                    </div>
                  </template>
                  <template v-slot:[`item.Ageing`]="{ item }">
                    <div class="FontSize">{{ checkTime(item.issue_reported_on) }}</div>
                    <div class="fontSizeSmall font-weight-bold">Last Updated {{ checkTime(item.last_updated_on) }}</div>
                  </template>
                  <template v-slot:[`item.issue_reported_platform`]="{ item }">
                    <div class="FontSize">
                      {{ item.issue_reported_platform ? item.issue_reported_platform.replaceAll("_", " ") : "-" }}
                    </div>
                  </template>
                  <template v-slot:[`item.issue_module_name`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span class="FontSize" v-bind="attrs" v-on="on">{{
                          item.issue_module_name
                            ? item.issue_module_name.length > 20
                              ? `${item.issue_module_name.slice(0, 20)}..`
                              : `${item.issue_module_name}`
                            : "-"
                        }}</span>
                      </template>
                      <span>{{ item.issue_module_name ? item.issue_module_name : "-" }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.project_name`]="{ item }">
                    <!-- <div class="FontSize font-weight-bold">{{ item.project_display_id }}</div> -->
                    <div class="FontSize font-weight-bold">{{ item.project_name }}</div>
                  </template>

                  <template v-slot:[`item.issue_severity`]="{ item }">
                    <div
                      class="FontSize"
                      :class="
                        item.issue_severity == 'HIGH'
                          ? 'red--text'
                          : item.issue_severity == 'MEDIUM'
                          ? 'deepYellowClass'
                          : 'blue--text'
                      "
                    >
                      {{ item.issue_severity ? item.issue_severity.replaceAll("_", " ") : "-" }}
                      <span class="black--text font-weight-bold" v-if="item.is_reopen == true">(RE-OPENED)</span>
                    </div>
                  </template>
                  <template v-slot:[`item.issue_assigned_to`]="{ item }">
                    <div class="FontSize">
                      {{ item.issue_assigned_to ? item.issue_assigned_to : "-" }}
                      <span class="font-weight-bold" v-if="$store.getters.get_user_email == item.issue_assigned_to"
                        >(Me)</span
                      >
                    </div>
                  </template>
                  <template v-slot:[`item.issue_status`]="{ item }">
                    <div class="FontSize">
                      {{ item.issue_status.replaceAll("_", " ") }}
                      <span class="black--text font-weight4x-bold" v-if="item.is_reopen == true">(RE-OPENED)</span>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="py-0">
                <v-data-table
                  v-if="renderTable == true"
                  hide-default-header
                  dense
                  :fixed-header="true"
                  :height="windowHeight"
                  class="elevation-0"
                  @click:row="checkRowItem"
                  :headers="projectHeaders"
                  :items="getAllProjectDetailsList"
                  :search="search"
                  :no-data-text="noDataText"
                  :footer-props="{
                    'items-per-page-options': [50, 100, 250, 500, 1000],
                  }"
                  :items-per-page="50"
                >
                  <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header">
                      <tr>
                        <th v-for="header in headers" :key="header.value" class="text-uppercase" scope="col">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">{{ header.text }}</span>
                            </template>
                            <span>{{ header.main_value }}</span>
                          </v-tooltip>
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:[`item.sl_no`]="{ item }">
                    <div class="FontSize">
                      {{ getAllProjectDetailsList.indexOf(item) + 1 }}
                    </div>
                  </template>
                  <template v-slot:[`item.project_name`]="{ item }">
                    <div class="FontSize font-weight-bold">{{ item.project_display_id }}</div>
                    <div class="FontSize">{{ item.project_name }}</div>
                  </template>
                  <template v-slot:[`item.created_by`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span class="FontSize" v-bind="attrs" v-on="on">{{
                          item.created_by.length > 10 ? `${item.created_by.slice(0, 10)}..` : item.created_by
                        }}</span>
                      </template>
                      <span class="FontSize">{{ item.created_by }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.roles`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="FontSize">
                          {{
                            item.roles.length == 1
                              ? item.roles[0].replaceAll("_", " ")
                              : `${item.roles[0].replaceAll("_", " ")} + ${item.roles.length - 1} Others`
                          }}
                        </div>
                      </template>
                      <div class="FontSize" v-for="(item, idx) in item.roles" :key="idx">
                        {{ item.replaceAll("_", " ") }}
                      </div>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.no_of_developers`]="{ item }">
                    <div class="FontSize ml-2">
                      {{ checkNumberOfDevelopers(item.no_of_users) }}

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            class="ml-3"
                            color="primary"
                            @click.stop="checkItem(item, 'MEMBERS')"
                            >mdi-information</v-icon
                          >
                        </template>
                        <span>View Member Details</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:[`item.no_of_unacknowledged_issues`]="{ item }">
                    <div class="FontSize pl-3">
                      {{ unacknowledgeissues(item.no_of_unacknowledged_issues) }}
                    </div>
                  </template>
                  <template v-slot:[`item.no_of_acknowledged_issues`]="{ item }">
                    <div class="FontSize pl-2">
                      {{
                        acknowledgeissues(
                          item.no_of_assigned_issues,
                          item.no_of_fixed_issues,
                          item.no_of_closed_issues,
                          item.no_of_rejected_issues
                        )
                      }}
                    </div>
                  </template>
                  <template v-slot:[`item.no_of_assigned_issues`]="{ item }">
                    <div class="FontSize pl-4">
                      {{ assignedissues(item.no_of_assigned_issues) }}
                    </div>
                  </template>
                  <template v-slot:[`item.no_of_fixed_issues`]="{ item }">
                    <div class="FontSize pl-2">
                      {{ numberOfFixedIssues(item.no_of_fixed_issues) }}
                    </div>
                  </template>
                  <template v-slot:[`item.no_of_closed_issues`]="{ item }">
                    <div class="FontSize pl-4">
                      {{ closedIssues(item.no_of_closed_issues) }}
                    </div>
                  </template>
                  <template v-slot:[`item.no_of_rejected_issues`]="{ item }">
                    <div class="FontSize pl-4">
                      {{ rejectedIssues(item.no_of_rejected_issues) }}
                    </div>
                  </template>
                  <template v-slot:[`item.Action`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          v-if="item.created_by != $store.getters.get_current_user_details.user_email_id"
                          icon
                          small
                          color="green"
                          @click.stop="checkItem(item, 'VIEW')"
                        >
                          <v-icon small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>View Project Details</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          v-if="item.created_by == $store.getters.get_current_user_details.user_email_id"
                          icon
                          small
                          color="green"
                          @click.stop="checkItem(item, 'EDIT')"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Project Details</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          v-if="item.created_by == $store.getters.get_current_user_details.user_email_id"
                          icon
                          small
                          :color="item.project_status == 'ACTIVE' ? 'red' : 'green'"
                          @click.stop="checkItem(item, 'ACTION')"
                        >
                          <v-icon small v-text="item.project_status == 'ACTIVE' ? 'mdi-close' : 'mdi-check'"></v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          item.project_status == 'ACTIVE'
                            ? `Deactivate ${item.project_name}`
                            : `Activate ${item.project_name}`
                        "
                      ></span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          v-if="
                            item.created_by == $store.getters.get_current_user_details.user_email_id &&
                            item.project_status !== 'INACTIVE'
                          "
                          icon
                          small
                          :color="'green'"
                          @click.stop="checkItem(item, 'TRANSFEROWNERSHIP')"
                        >
                          <v-icon small v-text="'mdi-account-plus'"></v-icon>
                        </v-btn>
                      </template>
                      <span>Transfer Ownership</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card outlined class="pa-3">
                <v-card height="190" class="elevation-3 pa-4" justify="center">
                  <v-toolbar dense class="elevation-1">
                    <div class="primary--text" v-text="'Enable/Disable Email:'"></div>
                  </v-toolbar>

                  <v-row>
                    <v-col md="2">
                      <v-switch
                        class="px-10 pb-5"
                        v-model="EmailSetting.EnableDisableEmail"
                        hide-details
                        inset
                        :label="EmailSetting.EnableDisableEmail == true ? 'Enabled' : 'Disabled'"
                      >
                      </v-switch>
                    </v-col>
                    <v-col>
                      <v-chip-group class="py-3" column active-class="primary" v-model="EmailSetting.EmailType">
                        <v-chip v-for="(item, idx) in EmailType" :value="item.value" :key="idx">{{ item.text }}</v-chip>
                      </v-chip-group>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="primary" @click="ValidateMethod" dense small
                      ><v-icon class="pr-3">mdi-check</v-icon> Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { GetEachProjectDetails } from "@/mixins/GetEachProjectDetails.js";
import { GetActionRights } from "@/mixins/GetActionRights.js";
import { GetAllUsersDetails } from "@/mixins/GetAllUsersDetails.js";
import { GetAllIssueComments } from "@/mixins/GetAllIssueComments.js";
// import BugReportPopUp from "@/components/Projects/BugReportPopUp.vue";
import { GetAllIssuesDetails } from "@/mixins/GetAllIssuesDetails.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
import ChangeIssuesStatusDialog from "@/components/Extras/ChangeIssuesStatusDialog.vue";
import { ListIssuesOfUserAcrossAllProjects } from "@/mixins/GetlistIssuesOfUserAcrossAllProjects.js";
import { GetCurrentUserDetails } from "@/mixins/GetCurrentUserDetails.js";
import { GetAllProjectsDetails } from "@/mixins/GetAllProjectsDetails.js";
import CreateProject from "@/components/Projects/Dialogs/CreateProject.vue";
import Overlay from "@/components/Extras/Overlay.vue";
import ChangeOwnerShip from "@/components/Projects/Dialogs/ChangeOwnerShip.vue";
import ActivateDeactivateProjectDialog from "@/components/Projects/Dialogs/ActivateDeactivateProjectDialog.vue";
import TestersDevelopersInformation from "@/components/Projects/Dialogs/TestersDevelopersInformation.vue";
import CreateIssueDialog from "@/components/Extras/CreateIssueDialog.vue";
import * as htmlToImage from "html-to-image";
import { updateUserEmailStatus } from "@/graphql/mutations.js";
import API, { graphqlOperation } from "@aws-amplify/api";
export default {
  components: {
    Snackbar,
    // ChangeIssuesStatusDialog,
    ChangeIssuesStatusDialog,

    Overlay,
    CreateProject,
    ChangeOwnerShip,
    CreateIssueDialog,
    TestersDevelopersInformation,
    ActivateDeactivateProjectDialog,
  },
  mixins: [
    GetEachProjectDetails,
    GetActionRights,
    GetAllUsersDetails,
    GetAllIssueComments,
    GetCurrentUserDetails,
    GetAllProjectsDetails,
    ListIssuesOfUserAcrossAllProjects,
    GetAllIssuesDetails,
    GetEachProjectDetails,
  ],
  data: () => ({
    callIntiateDataDialog: false,
    dialogChangeIssuesStatus: false,
    SnackBarComponent: {},
    model: true,
    model1: false,
    button: false,
    EmailType: [
      {
        text: "Immediate",
        value: "IMMEDIATE",
      },
      {
        text: "Daily",
        value: "DAILY",
      },
    ],
    EmailSetting: {
      EnableDisableEmail: true,
      EmailType: "",
    },
    tab: 0,
    Toggle: 0,
    ResultObject: [],
    selectedFilesFromDrop: [],
    websocketDataArray: [],
    StoreObj: {},
    notifactionDialig: false,
    search: "",
    windowHeight: 0,
    project_status: "ACTIVE",
    projects_tab: 0,
    overlay: false,
    current_pointer: 0,
    ListIssuesOfUserAcrossAllProjectsArray: [],
    GetAllIssueCommentsList: [],
    reportMenu: false,
    dialogCreateIssue: false,
    dialogCreateProject: false,
    dialogChangeOwnership: false,
    dialogActivateDeactivateProject: false,
    dialogTesterDeveloperInformation: false,
    BugReportPopUpVdialog: false,
    issuesHeader: [
      {
        text: "Project Name",
        value: "project_name",
        sortable: false,
      },
      {
        text: "Issue ID",
        value: "issue_id",
        sortable: false,
      },
      {
        text: "Title",
        value: "issue_module_name",
        sortable: false,
      },
      {
        text: "Ageing",
        value: "Ageing",
        sortable: false,
      },
      {
        text: "Reported By",
        value: "issue_reported_by",
        sortable: false,
      },
      {
        text: "Pending On",
        value: "issue_assigned_to",
        sortable: false,
      },

      {
        text: "Severity",
        value: "issue_severity",
        sortable: false,
      },
      {
        text: "Status",
        value: "issue_status",
        sortable: false,
      },
    ],
    projectHeaders: [
      {
        text: "Sl No.",
        value: "sl_no",
        main_value: "Serial Number",
      },
      {
        text: "Project",
        value: "project_name",
        main_value: "Project Details",
      },
      {
        text: "Owner",
        value: "created_by",
        main_value: "Owner",
      },
      {
        text: "My Roles",
        value: "roles",
        main_value: "Roles",
      },
      {
        text: "Members",
        value: "no_of_developers",
        main_value: "Total Members",
      },
      {
        text: "#UNACK",
        value: "no_of_unacknowledged_issues",
        main_value: "UnAcknowledged Issues",
      },
      {
        text: "#ACK",
        value: "no_of_acknowledged_issues",
        main_value: "Acknowledged Issues",
      },
      {
        text: "#ASGND",
        value: "no_of_assigned_issues",
        main_value: "Assigned Issues",
      },
      {
        text: "#FD",
        value: "no_of_fixed_issues",
        main_value: "Fixed Issues",
      },
      {
        text: "#CLSD",
        value: "no_of_closed_issues",
        main_value: "Closed Issues",
      },
      {
        text: "#RJTD",
        value: "no_of_rejected_issues",
        main_value: "Rejected Issues",
      },
      {
        text: "Action",
        value: "Action",
        main_value: "Action",
      },
    ],
    sort_by_items: [
      { text: "Alphabetical", value: "ALPHABETICAL" },
      { text: "Most Unacknowleged", value: "UNACKNOWLEDGED" },
      { text: "Most Acknowleged", value: "ACKNOWLEDGED" },
      { text: "Most Assigned", value: "ASSIGNED" },
      { text: "Most Fixed", value: "FIXED" },
      { text: "Most Closed", value: "CLOSED" },
    ],
    sort_by: "ALPHABETICAL",
    ConnectionDashboard: null,
    connected: false,
    renderTable: true,
  }),
  watch: {
    tab(val) {
      if (val == 1 || val == 2 || val == 0) {
        this.GetEachProjectDetailsMethod();
      }
    },
    model(val) {
      if (val == true) {
        this.enableOrDisableEmail();
      }
      if (val == false) {
        this.enableOrDisableEmail();
      }
    },
    sort_by(value) {
      if (value && value.length != "") {
        this.filterItems();
      }
    },
    "$store.getters.get_refresh"(value) {
      console.log("Check value", value);
      if (value == true) {
        if (value == true) {
          if (this.pendingTab) this.GetAllPendingIssuesMethods(this.issue_status);
          else this.GetAllIssuesOfUserMethod(this.issue_status);
        }
      }
    },
    async project_status(value) {
      await this.GetAllProjectsDetailsMethod(value);
      this.renderTableData();
    },
    getCurrentUserDetailsObject(value) {
      if (value && Object.keys(value).length != 0) {
        this.GetAllProjectsDetailsMethod(this.project_status);
      }
    },
  },
  async mounted() {
    await this.GetCurrentUser();
    this.checkActionRightsAccess();
    this.only_owner_condition = false;
    this.roles = this.$store.getters.get_current_project_details.roles;
    this.windowHeight = window.innerHeight - 190;
    await this.GetAllIssueCommentsMethod(this.StoreObj.issue_id);
    this.GetAllUsersDetailsMethod("DEVELOPER");
    this.GetAllIssuesDetailsMethod();
    // await this.GetEachProjectDetailsMethod();
    this.enableOrDisableEmail();
    this.websocketDataArray = [];
    setTimeout(() => {
      this.ListIssuesOfUserAcrossAllProjectsMethod();
    }, 500);
    this.windowHeight = window.innerHeight - 180;
    this.WebsocketConnection();
    this.EmailSetting.EnableDisableEmail = this.getCurrentUserDetailsObject.email_enabled == "ENABLED" ? true : false;
    this.EmailSetting.EmailType = this.getCurrentUserDetailsObject.email_type;
  },
  methods: {
    carouselMethod() {},
    acknowledgeissues(no_of_assigned_issues, no_of_fixed_issues, no_of_closed_issues, no_of_rejected_issues) {
      return no_of_assigned_issues + no_of_fixed_issues + no_of_closed_issues + no_of_rejected_issues > 9
        ? no_of_assigned_issues + no_of_fixed_issues + no_of_closed_issues + no_of_rejected_issues
        : " " + `00${no_of_assigned_issues + no_of_fixed_issues + no_of_rejected_issues + no_of_closed_issues}`;
    },
    checkNumberOfDevelopers(no_of_users) {
      return no_of_users > 9 ? no_of_users : " " + `0${no_of_users}`;
    },
    numberOfFixedIssues(no_of_fixed_issues) {
      return no_of_fixed_issues > 9 ? no_of_fixed_issues : " " + `0${no_of_fixed_issues}`;
    },
    closedIssues(no_of_closed_issues) {
      return no_of_closed_issues > 9 ? no_of_closed_issues : " " + `0${no_of_closed_issues}`;
    },
    rejectedIssues(no_of_rejected_issues) {
      return no_of_rejected_issues > 9 ? no_of_rejected_issues : " " + `0${no_of_rejected_issues}`;
    },

    assignedissues(no_of_assigned_issues) {
      return no_of_assigned_issues > 9 ? no_of_assigned_issues : " " + `0${no_of_assigned_issues}`;
    },
    unacknowledgeissues(no_of_unacknowledged_issues) {
      return no_of_unacknowledged_issues > 9? no_of_unacknowledged_issues : " " + `00${no_of_unacknowledged_issues}`;
    },
    ValidateMethod() {
      if (this.EmailSetting.EmailType == "") {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Select Email Type",
        };
      } else {
        this.enableOrDisableEmailMethod();
      }
    },
    async enableOrDisableEmailMethod() {
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        email_enabled: this.EmailSetting.EnableDisableEmail == true ? "ENABLED" : "DISABLED",
        email_type: this.EmailSetting.EmailType,
      };
      try {
        let result = await API.graphql(
          graphqlOperation(updateUserEmailStatus, {
            input: inputParams,
          })
        );
        this.overlay = true;
        var ResultObject = JSON.parse(result.data.UpdateUserEmailStatus);
        console.log("email enable or disable ", ResultObject);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: ResultObject.message,
        };
        await this.GetCurrentUser();
        console.log("Hai Bro Good Morning", this.getCurrentUserDetailsObject);
      } catch (error) {
        console.log(error);
      }
    },
    async enableOrDisableEmail(type) {
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
      };
      if (this.model == true) {
        inputParams.email_enabled = "ENABLED";
      } else {
        inputParams.email_enabled = "DISABLED";
      }
      if (this.model == true) {
        inputParams.email_type = type;
      }
    },
    checkTime(second) {
      var seconds = (new Date().getTime() - new Date(second).getTime()) / 1000;
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? " Day Ago" : " Days Ago") : "";
      var hDisplay = h > 0 ? h + (h == 1 ? " Hr Ago" : " hrs Ago") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " Min Ago" : " Mins Ago") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " Sec Ago" : " Secs Ago") : "";
      if (dDisplay != "") return dDisplay;
      else if (hDisplay != "") return hDisplay;
      else if (mDisplay != "") return mDisplay;
      else if (sDisplay) return sDisplay;
    },
    htmlToImageMethod() {
      this.overlay = true;
      this.$forceUpdate();
      this.selectedFilesFromDrop = [];
      var self = this;
      setTimeout(() => {
        htmlToImage.toBlob(this.$refs.home).then(function (blob) {
          blob.name = `ScreenShot${new Date().getTime()}.png`;
          self.overlay = false;
          self.selectedFilesFromDrop.push(blob);
          // self.dialogCreateIssue = true;
          let object = {
            selectedFilesFromDrop: self.selectedFilesFromDrop,
            routeName: self.$route.name,
          };
          self.$store.commit("SET_ISSUE_DETAILS", object);
          self.$router.push("ReportIssue");
        });
      }, 2000);
    },
    renderTableData() {
      this.renderTable = false;
      this.$nextTick(() => {
        this.renderTable = true;
      });
    },
    WebsocketConnection() {
      console.log("WebSocket Called!");
      var self = this;
      self.ConnectionDashboard = new WebSocket(
        `wss://6iqt568rfg.execute-api.ap-southeast-1.amazonaws.com/production?user_email_id=${this.$store.getters.get_current_user_details.user_email_id}`
      );
      self.ConnectionDashboard.onopen = function () {
        console.log("Connected!!!");
        self.connected = true;
      };
      self.ConnectionDashboard.onclose = function () {
        console.log("Disconnected On Close!!!");
        self.connected = false;
      };
      self.ConnectionDashboard.onerror = function () {
        self.connected = false;
        console.log("Disconnected On Error!!!");
      };
      self.ConnectionDashboard.onmessage = async function (event) {
        console.log(JSON.parse(event.data));
        self.websocketDataArray.push(JSON.parse(event.data));
        self.$forceUpdate();
        var audio = new Audio(console.log("NOTIFICATION audio is playing"), require("@/assets/r2d2.mp3"));
        audio.play();
      };
    },
    filterItems() {
      switch (this.sort_by) {
        case "ALPHABETICAL":
          this.getAllProjectDetailsList = this.getAllProjectDetailsList.sort((a, b) =>
            a.project_name.toLowerCase() < b.project_name.toLowerCase()
              ? -1
              : a.project_name.toLowerCase() > b.project_name.toLowerCase()
              ? 1
              : a.project_name.toLowerCase() < b.project_name.toLowerCase()
              ? -1
              : a.project_name.toLowerCase() > b.project_name.toLowerCase()
              ? 1
              : 0
          );
          break;
        case "UNACKNOWLEDGED":
          this.getAllProjectDetailsList = this.getAllProjectDetailsList.sort(function (x, y) {
            return y.no_of_unacknowledged_issues - x.no_of_unacknowledged_issues;
          });
          break;
        case "ACKNOWLEDGED":
          this.getAllProjectDetailsList = this.getAllProjectDetailsList.sort(function (x, y) {
            return (
              y.no_of_assigned_issues +
              y.no_of_fixed_issues +
              y.no_of_closed_issues -
              (x.no_of_assigned_issues + x.no_of_fixed_issues + x.no_of_closed_issues)
            );
          });
          break;
        case "ASSIGNED":
          this.getAllProjectDetailsList = this.getAllProjectDetailsList.sort(function (x, y) {
            return y.no_of_assigned_issues - x.no_of_assigned_issues;
          });
          break;
        case "FIXED":
          this.getAllProjectDetailsList = this.getAllProjectDetailsList.sort(function (x, y) {
            return y.no_of_fixed_issues - x.no_of_fixed_issues;
          });
          break;
        case "CLOSED":
          this.getAllProjectDetailsList = this.getAllProjectDetailsList.sort(function (x, y) {
            return y.no_of_closed_issues - x.no_of_closed_issues;
          });
          break;
      }
    },
    //importent statetements chANGE
    changeIssueStatus(item) {
      this.current_pointer = this.ListIssuesOfUserAcrossAllProjectsArray.indexOf(item);
      this.callIntiateDataDialog = false;
      this.StoreObj = item;
      this.$store.commit("SET_CURRENTPROJECT_DETAILS", item);
      this.dialogChangeIssuesStatus = true;
    },
    dialogCreateIssueEmit() {
      this.dialogCreateIssue = false;
      this.selectedFilesFromDrop = [];
    },
    notificationDialogEmit() {
      this.notification = false;
    },

    dialogChangeIssuesStatusEmit(Toggle) {
      if (Toggle == 1) {
        this.dialogChangeIssuesStatus = false;
        this.BugReportPopUpVdialog = false;
      }
      if (Toggle == 2) {
        if (this.pendingTab == 0) {
          this.GetAllPendingIssuesMethod(this.issue_status);
          this.GetEachProjectDetailsMethod();
        } else if (this.pendingTab == 1) {
          this.GetAllIssuesOfUserMethod(this.issue_status);
        }
        this.dialogChangeIssuesStatus = false;
        this.BugReportPopUpVdialog = false;
      } else if (Toggle == 3) {
        this.current_pointer -= 1;

        this.StoreObj = this.ListIssuesOfUserAcrossAllProjectsArray[this.current_pointer - 1];
        // this.callIntiateDataDialog = true;
        // this.StoreObj = this.GetAllIssueCommentsList[this.current_pointer];
      } else if (Toggle == 4) {
        this.current_pointer += 1;
        this.StoreObj = this.ListIssuesOfUserAcrossAllProjectsArray[this.current_pointer - 1];
        // this.callIntiateDataDialog = true;
        // this.StoreObj = this.GetAllIssueCommentsList[this.current_pointer];
      }
    },
    openBugTrakrForm() {
      window.open("http://www.bugtrakr.com/BugReportForm/BUGTRAKR");
    },
    async dialogChangeOwnershipEmit(Toggle) {
      this.dialogChangeOwnership = false;

      if (Toggle == 2) {
        await this.GetAllProjectsDetailsMethod(this.project_status);
        this.renderTableData();
      }
    },
    goInsideProject(item) {
      let project_details = {
        project_id: item.projectId,
      };
      this.$store.commit("SET_CURRENTPROJECT_DETAILS", project_details);
      this.$router.push("ProjectDetails");
    },
    checkRowItem(item) {
      this.$store.commit("SET_CURRENTPROJECT_DETAILS", item);
      this.$router.push("/ProjectDetails");
    },
    async dialogActivateDeactivateProjectEmit(Toggle) {
      this.dialogActivateDeactivateProject = false;
      if (Toggle == 2) {
        await this.GetAllProjectsDetailsMethod(this.project_status);
        this.renderTableData();
      }
    },
    async dialogTesterDeveloperInformationEmit(Toggle) {
      this.dialogTesterDeveloperInformation = false;
      if (Toggle == 2) {
        await this.GetAllProjectsDetailsMethod(this.project_status);
        this.renderTableData();
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action == "MEMBERS") {
        this.dialogTesterDeveloperInformation = true;
      } else if (action == "EDIT" || action == "VIEW") {
        this.dialogCreateProject = true;
      } else if (action == "ACTION") {
        this.dialogActivateDeactivateProject = true;
      } else if (action == "TRANSFEROWNERSHIP") {
        this.dialogChangeOwnership = true;
      }
    },
    async dialogCreateProjectEmit(Toggle) {
      this.dialogCreateProject = false;
      if (Toggle == 2) {
        await this.GetAllProjectsDetailsMethod(this.project_status);
        this.renderTableData();
      }
    },
  },
};
</script>
