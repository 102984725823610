import { listIssuesOfUserAcrossAllProjects } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export const ListIssuesOfUserAcrossAllProjects = {
  data: () => ({
    ListIssuesOfUserAcrossAllProjectsArray: [],
  }),
  methods: {
    async ListIssuesOfUserAcrossAllProjectsMethod() {
      try {
        this.overlay = true
        let result = await API.graphql(
          graphqlOperation(listIssuesOfUserAcrossAllProjects, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );
        this.ListIssuesOfUserAcrossAllProjectsArray = JSON.parse(result.data.ListIssuesOfUserAcrossAllProjects);
        this.overlay = false
        console.log("ListIssuesOfUserAcrossAllProjectsArray",this.ListIssuesOfUserAcrossAllProjectsArray)
      } catch (err) {
        console.log("error", err);
        this.ListIssuesOfUserAcrossAllProjectsArray = []
        this.overlay = false
      }
    },
  },
};
