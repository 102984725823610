<template>
  <div>
    <v-dialog persistent max-width="500px" v-model="dialogTesterDeveloperInformation">
      <v-card height="400px" class="overflow-y-auto">
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div v-text="'Project Members'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogTesterDeveloperInformationEmit(1)"> <v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-tabs class="pb-2 px-4" v-model="user_info_tab">
          <v-tab>Testers</v-tab>
          <v-tab>Developers</v-tab>
          <v-tab>Observers</v-tab>
        </v-tabs>
        <v-card-text class="text-center">
          <v-data-table dense class="elevation-0" :headers="headers" :items="GetAllUserDetailsList">
            <template v-slot:[`item.sl_no`]="{ item }">
              <div class="FontSize">
                {{ GetAllUserDetailsList.indexOf(item) + 1 }}
              </div>
            </template>
            <template v-slot:[`item.user_email_id`]="{ item }">
              <div class="FontSize">
                {{ item.user_email_id }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { GetAllUsersDetails } from "@/mixins/GetAllUsersDetails.js";
export default {
  props: {
    dialogTesterDeveloperInformation: Boolean,
    StoreObj: Object,
  },
  mixins: [GetAllUsersDetails],
  data: () => ({
    current_tab: "",
    user_info_tab: 0,
    user_email_id: "",
    testerDevelopersObserverHeaders: [
      { text: "Email ID", value: "user_email_id" },
      { text: "Added On", value: "added_on" },
    ],
    testerDevelopersObserverItems: [],
    GetAllUserDetailsList: [],
    newUsersArray: [],
    headers: [
      { text: "Sl No.", value: "sl_no" },
      { text: "Email ID", value: "user_email_id" },
    ],
  }),
  watch: {
    user_info_tab(value) {
      switch (value) {
        case 0:
          this.GetAllUsersDetailsMethod("TESTER", "DIALOG", this.StoreObj.project_id);
          this.current_tab = "TESTER";
          break;
        case 1:
          this.GetAllUsersDetailsMethod("DEVELOPER", "DIALOG", this.StoreObj.project_id);
          this.current_tab = "DEVELOPER";
          break;
        case 2:
          this.GetAllUsersDetailsMethod("OBSERVER", "DIALOG", this.StoreObj.project_id);
          this.current_tab = "OBSERVER";
          break;
      }
    },
    dialogTesterDeveloperInformation(value) {
      if (value == true) {
        this.newUsersArray = [];
        this.user_info_tab = 0;
        this.current_tab = "TESTER";
        this.GetAllUsersDetailsMethod("TESTER", "DIALOG", this.StoreObj.project_id);
      }
    },
  },
  methods: {
    dialogTesterDeveloperInformationEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
