<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent max-width="1000px" v-model="dialogCreateProject">
      <v-card>
        <v-toolbar dense dark class="elevation-0" color="primary">
          <div v-text="StoreObj.action == 'CREATE' ? 'Create Project' : 'Project Details'"></div>
          <v-spacer></v-spacer>
          <v-btn small icon @click="dialogCreateProjectEmit(1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0" v-if="StoreObj.action != 'VIEW'">
          <v-form ref="formCreateProject">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-row no-gutters class="mt-6">
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      class="FontSize field_height field_label_size mr-2"
                      label="Project Name"
                      v-model="create_project.project_name"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      :disabled="StoreObj.action == 'EDIT'"
                      class="FontSize field_height field_label_size"
                      label="Project Code"
                      maxlength="8"
                      v-model="create_project.project_display_id"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[a-zA-Z0-9]{0,64}$/.test(v) || 'Alphabets only',
                        (v) => (v && v.length > 7) || 'Minimum 8 Characters (Alphanumeric )',
                        (v) => (v && v.length < 9) || 'Maximum 8 Characters (Alphanumeric )',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <v-textarea
                      dense
                      outlined
                      rows="2"
                      class="FontSize field_height field_label_size"
                      label="Project Description"
                      v-model="create_project.project_description"
                      :rules="[(v) => !!v || 'Required']"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    class="my-1"
                    v-if="create_project.project_display_id && create_project.project_display_id != ''"
                  >
                    <div class="font-weight-bold">Bug Reporting Form URL :</div>
                    <div>http://www.bugtrakr.com/BugReportForm/{{ create_project.project_display_id }}</div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" class="font-weight-bold FontSize">
                    (MFA enabled for bug reporting by external users)
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-row class="mt-3 pl-4">
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <v-text-field
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                      label="Developer Lead"
                      v-model="create_project.developer_lead_email_id"
                      :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <v-text-field
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                      label="Test Lead"
                      v-model="create_project.tester_lead_email_id"
                      :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Email Must Be Valid']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <div class="fontSize font-weight-bold">Sub-systems to be tested</div>
                    <v-form ref="addPlatform">
                      <v-row no-gutters>
                        <v-col cols="12" xs="12" sm="12" md="5">
                          <v-text-field
                            dense
                            outlined
                            :rules="[(v) => !!v || 'Required']"
                            class="FontSize field_height field_label_size mr-2"
                            v-model="platform_settings.project_platform"
                            label="Sub-System"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="2">
                          <v-btn small color="primary" @click="validateSettingsMethod()">Add</v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-data-table
                      dense
                      class="elevation-0"
                      :headers="platformSettingsHeader"
                      :items="sub_systems"
                      hide-default-footer
                    >
                      <template v-slot:[`item.project_platform`]="{ item }">
                        <div class="FontSize">{{ item.project_platform }}</div>
                      </template>
                      <template v-slot:[`item.project_version`]="{ item }">
                        <div class="FontSize">{{ item.project_version }}</div>
                      </template>
                      <template v-slot:[`item.Action`]="{ item }">
                        <v-icon small color="red" @click="sub_systems.splice(sub_systems.indexOf(item), 1)"
                          >mdi-delete</v-icon
                        >
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="StoreObj.action != 'VIEW'">
          <v-spacer></v-spacer>
          <v-btn small outlined color="primary" @click="dialogCreateProjectEmit(1)">
            <v-icon>mdi-close</v-icon>
            Cancel
          </v-btn>
          <v-btn small :disabled="sub_systems.length == 0" :loading="loading" color="primary" @click="validateMethod()">
            <v-icon>mdi-check</v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
        <v-card-text class="pt-4" v-if="StoreObj.action == 'VIEW'">
          <v-row
            no-gutters
            v-for="(item, idx, i) in create_project"
            :key="i"
            class="fontSize"
            v-show="idx != 'mfa_enabled'"
          >
            <v-col cols="12" xs="12" sm="12" md="4" class="font-weight-bold">
              {{ idx.toUpperCase().replaceAll("_", " ") }}
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8"> :{{ item }} </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { createProject, editProject } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogCreateProject: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    SnackBarComponent: {},
    create_project: {
      project_display_id: "",
      project_name: "",
      project_description: "",
      project_website_url: "",
      mfa_enabled: false,
      developer_lead_email_id: "",
      tester_lead_email_id: "",
    },
    platform_settings: {
      project_platform: "",
    },
    sub_systems: [],
    platformSettingsHeader: [
      { text: "Sub-Sytem Name", value: "project_platform" },
      { text: "Action", value: "Action" },
    ],
    loading: false,
  }),
  watch: {
    "create_project.project_display_id"(value) {
      if (value) {
        this.create_project.project_display_id = value.toUpperCase();
      }
    },
    dialogCreateProject(value) {
      if (value == true) {
        if (this.StoreObj.action == "CREATE") {
          this.create_project.developer_lead_email_id = this.$store.getters.get_user_email;
          this.create_project.tester_lead_email_id = this.$store.getters.get_user_email;
        }
        if (this.StoreObj.action == "EDIT" || this.StoreObj.action == "VIEW") {
          this.create_project.project_display_id = this.StoreObj.project_display_id;
          this.create_project.project_name = this.StoreObj.project_name;
          this.create_project.project_description = this.StoreObj.project_description;
          this.create_project.project_website_url = this.StoreObj.project_website_url
            ? this.StoreObj.project_website_url
            : "";
          this.create_project.mfa_enabled = this.StoreObj.mfa_enabled;
          this.create_project.developer_lead_email_id = this.StoreObj.developer_lead_email_id.trim();
          this.create_project.tester_lead_email_id = this.StoreObj.tester_lead_email_id.trim();
          this.create_project.bug_reporting_form_url = `http://www.bugtrakr.com/BugReportForm/${this.create_project.project_display_id}`;
          this.sub_systems = this.StoreObj.sub_systems;
          console.log("Check StoreObj", this.create_project);
        }
      }
    },
  },
  methods: {
    validateMethod() {
      if (this.$refs.formCreateProject.validate()) {
        // if (this.sub_systems.length != 0) {
        this.createProjectMethod();
        // } else {
        //   this.SnackBarComponent = {
        //     SnackbarVmodel: true,
        //     SnackbarColor: "red",
        //     Top: true,
        //     SnackbarText: "Please Select Platform",
        //   };
        // }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Input (s)",
        };
      }
    },
    async createProjectMethod() {
      var mutationname = this.StoreObj.action == "CREATE" ? createProject : editProject;
      var mutationresult = this.StoreObj.action == "CREATE" ? "CreateProject" : "EditProject";
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.StoreObj.action == "EDIT" ? this.StoreObj.project_id : undefined,
        project_name: this.create_project.project_name,
        project_description: this.create_project.project_description,
        sub_systems: this.sub_systems ? this.sub_systems : undefined,
        project_website_url:
          this.create_project.project_website_url && this.create_project.project_website_url != ""
            ? this.create_project.project_website_url
            : undefined,
        enable_external_bug_reporting: this.StoreObj.action == "CREATE" ? true : undefined,
        developer_lead_email_id: this.create_project.developer_lead_email_id,
        tester_lead_email_id: this.create_project.tester_lead_email_id,
        project_display_id: this.StoreObj.action == "CREATE" ? this.create_project.project_display_id : undefined,
        mfa_enabled: true,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.dialogCreateProjectEmit((this.Toggle = 2));
        } else if (ResultObject.status == "FAILURE") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: ResultObject.message,
          };
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    validateSettingsMethod() {
      if (this.$refs.addPlatform.validate()) {
        if (
          this.sub_systems.filter((platform) => platform.project_platform == this.platform_settings.project_platform)
            .length == 0
        ) {
          let platform_settings = { ...this.platform_settings };
          this.sub_systems.push(platform_settings);
          this.$refs.addPlatform.reset();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Sub-System Already Exists",
          };
        }
      }
    },
    dialogCreateProjectEmit(Toggle) {
      this.sub_systems = [];
      if (this.$refs.formCreateProject) {
        this.$refs.formCreateProject.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
