<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogActivateDeactivateProject" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div><span v-text="StoreObj.project_status == 'ACTIVE' ? 'De-Activate' : 'Activate'"></span> Project</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogActivateDeactivateProjectEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-icon size="50px" :color="StoreObj.project_status == 'ACTIVE' ? 'red' : 'green'" v-text="StoreObj.project_status == 'ACTIVE' ? 'mdi-close' : 'mdi-check'"></v-icon>
          <div>Are you sure you want to <span v-text="StoreObj.project_status == 'ACTIVE' ? 'Deactivate' : 'Activate'"></span> {{ StoreObj.project_name }} ?</div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius text-capitalize" color="primary" outlined @click="dialogActivateDeactivateProjectEmit(1)"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="activateOrDeactivateProjectMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { closeOrReopenProject } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogActivateDeactivateProject: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {
    dialogActivateDeactivateProject(value) {
      if (value == true) {
        console.log("Check StoreObj", this.StoreObj);
      }
    },
  },
  methods: {
    async activateOrDeactivateProjectMethod() {
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.StoreObj.project_id,
        action_status: this.StoreObj.project_status == "ACTIVE" ? "DEACTIVATE" : "ACTIVATE",
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(closeOrReopenProject, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CloseOrReopenProject);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.dialogActivateDeactivateProjectEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.log("Check The Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogActivateDeactivateProjectEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
