import { getCurrentUser } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetCurrentUserDetails = {
  data() {
    return {
      getCurrentUserDetailsObject: {},
      overlay: false,
      RouterList: [],
      user_type: "",
    };
  },
  methods: {
    async GetCurrentUser() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(getCurrentUser, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );
        this.$store.commit("SET_CURRENTUSER_DETAILS", JSON.parse(result.data.GetCurrentUser).response);
        this.getCurrentUserDetailsObject = JSON.parse(result.data.GetCurrentUser).response;
        console.warn("getCurrentUserDetailsObject", this.getCurrentUserDetailsObject);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.$router.push("/");
        console.log(error);
      }
    },
  },
};
