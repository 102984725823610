import { listProjectsOfAnUser } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllProjectsDetails = {
  data() {
    return {
      noDataText: "",
      getAllProjectDetailsList: [],
      overlay: false,
    };
  },
  methods: {
    async GetAllProjectsDetailsMethod(project_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listProjectsOfAnUser, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
              project_status,
            },
          })
        );
        this.getAllProjectDetailsList = JSON.parse(result.data.ListProjectsOfAnUser);
        if (this.getAllProjectDetailsList.length == 0) {
          this.noDataText = "No Project Found";
        }
        console.warn("getAllProjectDetailsList", this.getAllProjectDetailsList);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.noDataText = error.errors[0].message;
        this.getAllProjectDetailsList = [];
        console.log(error);
      }
    },
  },
};
